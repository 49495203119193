import { mapState, mapActions } from 'vuex'
import TwoColumnHeaderPartial from '@/pages/lib/partials/TwoColumnHeaderPartial/TwoColumnHeaderPartial.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TwoColumnHeaderPartial,
    TxButton
  },
  data () {
    return {
      isBusySignIn: false
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    }),
    launchpadPromoContent () {
      return {
        title: this.i18nSection('launchpadPromoTitle'),
        description: this.i18nSection('launchpadPromoDescription'),
        subtitle: this.i18nSection('launchpadPromoSubTitle')
      }
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignIn () {
      this.isBusySignIn = true
      this.authInitiate()
    }
  }
}
