import SupportersItem from './SupportersItem/SupportersItem.vue'

export default {
  components: {
    SupportersItem
  },
  computed: {
    supporters () {
      return [
        {
          title: 'laborx',
          imgUrl: 'src/supporters/laborx.png'
        },
        {
          title: 'switchmaven',
          imgUrl: 'src/supporters/switchmaven.png'
        },
        {
          title: 'MHC Digital Finance',
          imgUrl: 'src/supporters/mhc.png'
        },
        {
          title: 'Pacifica Solution',
          imgUrl: 'src/supporters/pacifica.png'
        }
      ]
    }
  }
}
