import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TxButton
  },
  props: {
    item: Object // first line text, second line text
  }
}
