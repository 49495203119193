import FinanceAndCurrenciesItem from './FinanceAndCurrenciesItem/FinanceAndCurrenciesItem.vue'

export default {
  components: {
    FinanceAndCurrenciesItem
  },
  computed: {
    featuresItems () {
      return [
        {
          coinName: 'USDT',
          iconUrl: 'src/coins/usdt.svg'
        },
        {
          coinName: 'USDC',
          iconUrl: 'src/coins/usdc.svg'
        },
        {
          coinName: 'DAI',
          iconUrl: 'src/coins/dai.svg'
        },
        {
          coinName: 'BUSD',
          iconUrl: 'src/coins/busd.svg'
        },
        {
          coinName: 'TIME',
          iconUrl: 'src/coins/time.svg'
        }
      ]
    }
  }
}
