import { mapGetters, mapState } from 'vuex'
import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

import LaunchpadPromoSection from './LaunchpadPromoSection/LaunchpadPromoSection.vue'
import MetaverseSection from './MetaverseSection/MetaverseSection.vue'
import FinanceAndCurrenciesSection from './FinanceAndCurrenciesSection/FinanceAndCurrenciesSection.vue'
import OurTeamSection from './OurTeamSection/OurTeamSection.vue'
import ContactsSection from './ContactsSection/ContactsSection.vue'
import SupportersSection from './SupportersSection/SupportersSection.vue'
import TimeToPlaySection from './TimeToPlaySection/TimeToPlaySection.vue'

export default {
  components: {
    NavBar,
    LaunchpadPromoSection,
    MetaverseSection,
    FinanceAndCurrenciesSection,
    SupportersSection,
    OurTeamSection,
    TimeToPlaySection,
    ContactsSection,
    PublicFooter,
    TxButton
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    ...mapState({
      partners: state => state.public.partners
    })
  },
  methods: {
    handleCgu () {
      window.open('https://www.cgu.io/', '_blank')
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
